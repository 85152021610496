import React from 'react';
import FightGame from '../components/game/FightGame';
import './Blog.css';
import SEO from '../components/SEO';

const Blog: React.FC = () => {
    React.useEffect(() => {
        const schema = {
            "@context": "https://schema.org",
            "@type": "SportsEvent",
            "name": "Jake Paul vs Mike Tyson Boxing Match",
            "startDate": "2023-07-20",
            "location": {
                "@type": "Place",
                "name": "AT&T Stadium",
                "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "Arlington",
                    "addressRegion": "TX",
                    "addressCountry": "US"
                }
            },
            "competitor": [
                {
                    "@type": "Person",
                    "name": "Jake Paul",
                    "nationality": "American",
                    "height": "185cm",
                    "weight": "185 lbs",
                    "age": "27"
                },
                {
                    "@type": "Person",
                    "name": "Mike Tyson",
                    "nationality": "American",
                    "height": "178cm",
                    "weight": "220 lbs",
                    "age": "57"
                }
            ],
            "sport": "Boxing",
            "description": "Historic boxing match between Jake Paul and Mike Tyson, featuring an interactive game experience.",
            "organizer": {
                "@type": "Organization",
                "name": "Netflix"
            },
            "result": {
                "@type": "CompetitionResult",
                "winner": "Jake Paul",
                "result": "Split Decision"
            }
        };

        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schema);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <article className="blog-container" itemScope itemType="https://schema.org/Article">
            <SEO 
                title="Jake Paul vs Mike Tyson | Historic Boxing Match Replay"
                description="Relive the historic boxing match between Jake Paul and Mike Tyson. Play the interactive game, see match highlights, and explore detailed fighter statistics."
                image="/images/og-image.jpg"
            />
            <header className="blog-header">
                <div className="title-container">
                    <h1 itemProp="headline">Jake Paul vs Mike Tyson</h1>
                    <p className="subtitle" itemProp="alternativeHeadline">
                        The Historic Clash That Shocked The World
                    </p>
                </div>
                <div className="event-details" itemProp="about">
                    <time itemProp="datePublished" dateTime="2023-07-20">
                        July 20, 2023
                    </time>
                    <div className="detail-item">
                        <span className="icon">📍</span>
                        <span className="venue">AT&T Stadium, Arlington, Texas</span>
                    </div>
                    <div className="detail-item">
                        <span className="icon">📺</span>
                        <span>Streamed on Netflix</span>
                    </div>
                </div>
            </header>
            
            <main>
                <section className="game-section">
                    <div className="game-intro">
                        <h2>Relive The Fight And Play</h2>
                        <p>Experience the historic moment that changed boxing forever</p>
                    </div>
                    <FightGame />
                </section>

                <section className="fight-info" itemProp="articleBody">
                    <div className="fighter-stats">
                        <div className="fighter jake-paul" itemScope itemType="https://schema.org/Person">
                            <img src="/images/jake-full.jpeg" alt="Jake Paul in fighting stance" className="fighter-image" itemProp="image" />
                            <h3 itemProp="name">Jake Paul</h3>
                            <ul>
                                <li itemProp="age">Age: 27</li>
                                <li>Record: <span itemProp="award">10-1-0</span></li>
                                <li>KOs: 7</li>
                                <li>Victory by: Split Decision</li>
                                <li itemProp="height">Height: 6'1" (185cm)</li>
                                <li itemProp="weight">Weight: 185 lbs</li>
                                <li>Reach: 76 inches</li>
                            </ul>
                            <div className="fighter-bio" itemProp="description">
                                From social media star to legitimate boxer, Jake Paul has proven his worth in the ring with impressive victories over MMA champions and boxing veterans.
                            </div>
                        </div>
                        <div className="vs">VS</div>
                        <div className="fighter mike-tyson" itemScope itemType="https://schema.org/Person">
                            <img src="/images/tyson-full.jpeg" alt="Mike Tyson showing his legendary form" className="fighter-image" itemProp="image" />
                            <h3 itemProp="name">Mike Tyson</h3>
                            <ul>
                                <li itemProp="age">Age: 57</li>
                                <li>Record: <span itemProp="award">50-7-0</span></li>
                                <li>KOs: 44</li>
                                <li>Impressive Performance</li>
                                <li itemProp="height">Height: 5'10" (178cm)</li>
                                <li itemProp="weight">Weight: 220 lbs</li>
                                <li>Reach: 71 inches</li>
                            </ul>
                            <div className="fighter-bio" itemProp="description">
                                The youngest heavyweight champion in history, Iron Mike Tyson remains one of boxing's most iconic figures, known for his explosive power and intimidating presence.
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </article>
    );
};

export default Blog; 