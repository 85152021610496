import React, { useEffect } from 'react';
import Blog from './blog/Blog';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

function ErrorFallback({ error }: FallbackProps) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
}

const App: React.FC = () => {
  useEffect(() => {
    try {
      if (window.adsbygoogle) {
        window.adsbygoogle.push({});
      }
    } catch (error) {
      console.error('Error initializing ads:', error);
    }
  }, []);

  return (
    <HelmetProvider>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div className="app">
          <Blog />
          <ins className="adsbygoogle"
               style={{ display: 'block' }}
               data-ad-client="ca-pub-1632003121930633"
               data-ad-slot="auto"
               data-ad-format="auto"
               data-full-width-responsive="true"></ins>
        </div>
      </ErrorBoundary>
    </HelmetProvider>
  );
};

export default App;
