import React, { useEffect, useRef, useState } from 'react';
import './FightGame.css';
import Modal from '../shared/Modal';

interface Character {
    name: string;
    image: string;
    width: number;
    height: number;
}

enum GamePhase {
    CHOOSE_PLAYER = 'CHOOSE_PLAYER',
    FIGHT = 'FIGHT'
}

interface SlidingMessage {
    active: boolean;
    x: number;
    text: string;
}

const isMobile = window.innerWidth <= 840;
const mobileSpeedMultiplier = 2;

const logGameStatus = (message: string) => {
    const logStyle = 'background: #333; color: #fff; padding: 2px 5px; border-radius: 3px;';
    console.log(`%c${message}`, logStyle);
};

// Add this at the top of your file or in a separate type declaration file
declare global {
    interface Window {
        gtag?: (...args: any[]) => void;
    }
}

const FightGame: React.FC = () => {
    const [selectedCharacter, setSelectedCharacter] = useState<Character | null>(null);
    const [gameStarted, setGameStarted] = useState(false);
    const [score, setScore] = useState(0);
    const [gameOver, setGameOver] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const gameRef = useRef<number>(0);
    const playerRef = useRef({
        y: 200,
        velocity: 0,
        jumping: false
    });
    const chaserRef = useRef({
        x: 800,
        y: 200,
        speed: 4 * mobileSpeedMultiplier
    });
    const animationRef = useRef({
        bobOffset: 0,
        bobDirection: 1
    });
    const jumpScoredRef = useRef(false);
    const scoreRef = useRef(0);
    const [flipFlops, setFlipFlops] = useState(0);
    const flipFlopRef = useRef(0);
    const canFlipRef = useRef(true);
    const flipFlopAnimationRef = useRef({
        isAnimating: false,
        scale: 1,
        direction: 1
    });
    const playerRotationRef = useRef(0);
    const isFlippingRef = useRef(false);
    const popupAnimationRef = useRef({
        active: false,
        scale: 0.1,
        opacity: 1,
        text: "FLIP FLOP!"
    });
    const successfulJumpsRef = useRef(0);
    const backgroundRef = useRef({
        circles: Array.from({ length: isMobile ? 3 : 10 }, () => ({
            x: Math.random() * 800,
            y: Math.random() * 600,
            size: isMobile ? 10 : Math.random() * 20 + 10,
            speed: Math.random() * 2 + 0.5,
            color: `hsl(${Math.random() * 360}, 70%, 70%)`
        }))
    });
    const [currentPhase, setCurrentPhase] = useState<GamePhase>(GamePhase.CHOOSE_PLAYER);
    const slidingMessageRef = useRef<SlidingMessage>({
        active: false,
        x: 800, // Start from right edge
        text: "Each 5 points = 1 Flip Flop! 🔄"
    });

    const characters: Character[] = [
        { 
            name: 'Jake Paul',
            image: '/images/jake.png',
            width: 100,
            height: 100
        },
        { 
            name: 'Mike Tyson',
            image: '/images/tyson.png',
            width: 100,
            height: 100
        }
    ];

    // Preload images
    useEffect(() => {
        const images = characters.map(char => {
            const img = new Image();
            img.src = char.image;
            return new Promise((resolve, reject) => {
                img.onload = resolve;
                img.onerror = reject;
            });
        });

        Promise.all(images)
            .then(() => setImagesLoaded(true))
            .catch(err => console.error('Error loading images:', err));
    }, []);

    useEffect(() => {
        logGameStatus(`Device Type: ${isMobile ? 'Mobile' : 'Desktop'}`);
        logGameStatus(`Speed Multiplier: ${mobileSpeedMultiplier}x`);
    }, []);

    useEffect(() => {
        // Add meta tags dynamically
        document.title = "Tyson vs Paul - Epic Boxing Game Showdown";
        
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', 'Play the ultimate boxing showdown between Mike Tyson and Jake Paul. Experience intense boxing action, score points, and unlock special moves in this exciting fighting game.');
        }
    }, []);

    const startGame = () => {
        if (!selectedCharacter || !canvasRef.current) return;
        
        logGameStatus('Game Started');
        logGameStatus(`Initial Speed: ${chaserRef.current.speed}`);
        
        // Google Analytics event for game start
        if (window.gtag) {
            window.gtag('event', 'game_start', {
                event_category: 'Game',
                event_label: selectedCharacter.name,
            });
        }

        scoreRef.current = 0;
        setScore(0);
        setFlipFlops(0);
        flipFlopRef.current = 0;
        successfulJumpsRef.current = 0;
        canFlipRef.current = true;
        setGameOver(false);
        chaserRef.current.x = 800;
        chaserRef.current.speed = 4 * mobileSpeedMultiplier;
        playerRef.current = { y: 200, velocity: 0, jumping: false };
        jumpScoredRef.current = false;
        playerRotationRef.current = 0;
        isFlippingRef.current = false;
        
        // Start the game loop
        gameLoop();
    };

    const jump = () => {
        if (!playerRef.current.jumping) {
            const jumpVelocity = -7;
            playerRef.current.velocity = jumpVelocity * mobileSpeedMultiplier;
            playerRef.current.jumping = true;
            canFlipRef.current = true;
            logGameStatus(`Jump velocity: ${jumpVelocity}`);
            
            // Google Analytics event for jump
            if (window.gtag) {
                window.gtag('event', 'jump', {
                    event_category: 'Game',
                    event_label: 'Player Jump',
                });
            }
        } else if (canFlipRef.current && flipFlopRef.current > 0) {
            const flipVelocity = -5;
            playerRef.current.velocity = flipVelocity * mobileSpeedMultiplier;
            flipFlopRef.current -= 1;
            setFlipFlops(flipFlopRef.current);
            canFlipRef.current = false;
            isFlippingRef.current = true;
            playerRotationRef.current = 0;
            logGameStatus(`Flip jump velocity: ${flipVelocity}`);
            
            // Google Analytics event for flip jump
            if (window.gtag) {
                window.gtag('event', 'flip_jump', {
                    event_category: 'Game',
                    event_label: 'Player Flip Jump',
                });
            }
        }
    };

    const seededRandom = (seed: number) => {
        const x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
    };

    const gameLoop = () => {
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext('2d');
        if (!canvas || !ctx || !selectedCharacter) return;

        // Clear canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw base background with gradient
        const bgGradient = ctx.createRadialGradient(
            canvas.width/2, canvas.height/2, 0,
            canvas.width/2, canvas.height/2, canvas.width/2
        );
        bgGradient.addColorStop(0, '#2d2d2d');
        bgGradient.addColorStop(1, '#1a1a1a');
        ctx.fillStyle = bgGradient;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Draw animated circles
        backgroundRef.current.circles.forEach(circle => {
            ctx.beginPath();
            ctx.fillStyle = circle.color;
            ctx.globalAlpha = 0.1;
            ctx.arc(circle.x, circle.y, circle.size, 0, Math.PI * 2);
            ctx.fill();
            
            const speedMultiplier = 1 + (scoreRef.current * 0.1);
            circle.y += circle.speed * speedMultiplier;
            if (circle.y > canvas.height + circle.size) {
                circle.y = -circle.size;
                circle.x = Math.random() * canvas.width;
            }
        });
        ctx.globalAlpha = 1;

        // Draw ropes with glow
        for(let i = 1; i <= 3; i++) {
            const y = 150 + (i * 80);
            ctx.beginPath();
            ctx.strokeStyle = 'rgba(255, 215, 0, 0.3)';
            ctx.lineWidth = 2;
            ctx.moveTo(50, y);
            ctx.lineTo(750, y);
            ctx.shadowColor = '#ffd700';
            ctx.shadowBlur = 10;
            ctx.stroke();
            ctx.shadowBlur = 0;
        }

        // Draw floor
        const floorY = 500;
        const floorGradient = ctx.createLinearGradient(0, floorY, 0, canvas.height);
        floorGradient.addColorStop(0, '#333333');
        floorGradient.addColorStop(1, '#1a1a1a');
        ctx.fillStyle = floorGradient;
        ctx.fillRect(0, floorY, canvas.width, canvas.height - floorY);

        // Update player physics
        if (playerRef.current.velocity > 0) {
            playerRef.current.velocity += 0.15;
        } else {
            playerRef.current.velocity += 0.3;
        }
        
        playerRef.current.y += playerRef.current.velocity;

        // Ground collision
        const groundY = floorY - 100;
        if (playerRef.current.y > groundY) {
            playerRef.current.y = groundY;
            playerRef.current.velocity = 0;
            playerRef.current.jumping = false;
        }

        // Draw player (selected character)
        const playerImg = new Image();
        playerImg.src = selectedCharacter.image;
        
        ctx.save();
        ctx.translate(100 + 50, playerRef.current.y + 50);
        
        if (isFlippingRef.current) {
            playerRotationRef.current += 8;
            if (playerRotationRef.current >= 360) {
                isFlippingRef.current = false;
                playerRotationRef.current = 0;
            }
        }
        ctx.rotate((playerRotationRef.current * Math.PI) / 180);
        
        ctx.drawImage(
            playerImg,
            -50,
            -50,
            100,
            100
        );
        ctx.restore();

        // Draw chaser (opponent character)
        const chaserImg = new Image();
        // Use the opposite character as the chaser
        chaserImg.src = selectedCharacter.name === 'Jake Paul' 
            ? '/images/tyson.png' 
            : '/images/jake.png';
        
        ctx.save();
        ctx.scale(-1, 1);  // Flip horizontally
        ctx.drawImage(
            chaserImg,
            -chaserRef.current.x - 100,
            chaserRef.current.y,
            100,
            100
        );
        ctx.restore();

        // Update chaser position and check for score
        chaserRef.current.y = groundY;
        const previousX = chaserRef.current.x;  // Store current position
        chaserRef.current.x -= chaserRef.current.speed;  // Update position

        // Score logic - check if chaser passed player position
        if (previousX > 100 && chaserRef.current.x <= 100 && !jumpScoredRef.current) {
            scoreRef.current += 1;
            setScore(scoreRef.current);
            jumpScoredRef.current = true;

            // Speed increase
            const newSpeed = (2 + (scoreRef.current * 0.08)) * mobileSpeedMultiplier;
            chaserRef.current.speed = newSpeed;

            // Show appropriate message based on score
            let message = "";
            if (scoreRef.current === 2) message = "Each 5 points = 1 Flip Flop! 🔄";
            else if (scoreRef.current === 6) message = "Double Jump to use Flip Flop! 🦘";
            else if (scoreRef.current === 12) message = "Getting Faster! Keep Up! ⚡";
            else if (scoreRef.current === 20) message = "Still Standing? Impressive! 🥊";
            else if (scoreRef.current === 25) message = "You're On Fire! 🔥";
            else if (scoreRef.current === 30) message = "Is This Your Final Form? 💪";
            else if (scoreRef.current === 40) message = "Legendary Status Approaching! ⭐";
            else if (scoreRef.current === 50) message = "Halfway to Glory! 👑";
            else if (scoreRef.current === 60) message = "You're Making This Look Easy! 😎";
            else if (scoreRef.current === 70) message = "Unstoppable Machine! 🤖";
            else if (scoreRef.current === 80) message = "Are You Even Human? 👽";
            else if (scoreRef.current === 90) message = "The Final Countdown! ⏰";
            else if (scoreRef.current === 100) message = "GODLIKE! YOU'RE A LEGEND! 🏆";

            if (message) {
                slidingMessageRef.current = {
                    active: true,
                    x: 800,
                    text: message
                };
                // Reduce timeout to match animation (reduced from 6000 to 4000)
                setTimeout(() => {
                    slidingMessageRef.current.active = false;
                }, 4000);
            }

            // Make sure popup animation is triggered for flip flops
            if (scoreRef.current % 5 === 0) {
                flipFlopRef.current += 1;
                setFlipFlops(flipFlopRef.current);
                popupAnimationRef.current = {
                    active: true,
                    scale: 0.1,
                    opacity: 1,
                    text: "FLIP FLOP!"
                };
            }
        }

        // Draw flip flop counter if player has any
        if (flipFlopRef.current > 0) {
            ctx.fillStyle = '#ffffff';
            ctx.font = 'bold 24px Arial';
            const flipFlopText = `Flip Flops: ${flipFlopRef.current}`;
            const textWidth = ctx.measureText(flipFlopText).width;
            ctx.fillText(flipFlopText, canvas.width - textWidth - 20, 40);
        }

        // Reset chaser position
        if (chaserRef.current.x < -100) {
            chaserRef.current.x = canvas.width + 200;
            chaserRef.current.speed = 4 * mobileSpeedMultiplier + (scoreRef.current * 0.2);
            jumpScoredRef.current = false;  // Reset jump scored flag
        }

        // Score display
        ctx.fillStyle = '#ffffff';
        ctx.font = 'bold 24px Arial';
        ctx.fillText(`Score: ${scoreRef.current}`, 20, 40);

        // Collision detection
        if (!playerRef.current.jumping && 
            Math.abs(100 - chaserRef.current.x) < 60 &&
            Math.abs(playerRef.current.y - chaserRef.current.y) < 80) {
            setGameOver(true);
            return;
        }

        // Draw popup animation if active
        if (popupAnimationRef.current.active) {
            ctx.save();
            
            // Center of screen
            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
            
            // Update animation
            popupAnimationRef.current.scale += 0.02;  // Slow growth
            popupAnimationRef.current.opacity = Math.max(0, 2 - (popupAnimationRef.current.scale - 1));
            
            // Apply transformations
            ctx.translate(centerX, centerY);
            ctx.scale(popupAnimationRef.current.scale, popupAnimationRef.current.scale);
            
            // Draw text with cartoon style
            ctx.font = 'bold 48px Arial';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            
            // Outline
            ctx.strokeStyle = '#000000';
            ctx.lineWidth = 8;
            ctx.globalAlpha = popupAnimationRef.current.opacity;
            ctx.strokeText(popupAnimationRef.current.text, 0, 0);
            
            // Fill with gradient
            const gradient = ctx.createLinearGradient(0, -24, 0, 24);
            gradient.addColorStop(0, '#FFD700');  // Gold
            gradient.addColorStop(1, '#FFA500');  // Orange
            ctx.fillStyle = gradient;
            ctx.fillText(popupAnimationRef.current.text, 0, 0);
            
            ctx.restore();

            // End animation when opacity reaches 0
            if (popupAnimationRef.current.opacity <= 0) {
                popupAnimationRef.current.active = false;
            }
        }

        gameRef.current = requestAnimationFrame(gameLoop);
    };

    useEffect(() => {
        if (gameOver) {
            cancelAnimationFrame(gameRef.current);
            
            // Google Analytics event for game over
            if (window.gtag) {
                window.gtag('event', 'game_over', {
                    event_category: 'Game',
                    event_label: 'Game Over',
                    value: scoreRef.current,
                });
            }
        }
    }, [gameOver]);

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.code === 'Space') {
                if (!gameStarted && selectedCharacter) {
                    startGame();
                } else if (gameStarted && !gameOver) {
                    jump();
                }
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [gameStarted, selectedCharacter, gameOver]);

    useEffect(() => {
        if (selectedCharacter && currentPhase === GamePhase.FIGHT && !gameStarted) {
            const canvas = canvasRef.current;
            if (canvas) {
                setGameStarted(true);
                startGame();
            }
        }
    }, [selectedCharacter, currentPhase]);

    const handlePlayerSelect = (selectedPlayer: Character) => {
        setSelectedCharacter(selectedPlayer);
        setCurrentPhase(GamePhase.FIGHT);
    };

    return (
        <div className="fight-game-container">
            <h1 className="visually-hidden">Mike Tyson vs Jake Paul Boxing Game</h1>
            
            {currentPhase === GamePhase.CHOOSE_PLAYER && (
                <div className="character-select">
                    <div className="arena-background">
                        <div className="spotlight-left"></div>
                        <div className="spotlight-right"></div>
                        <div className="ring-ropes">
                            <div className="rope"></div>
                            <div className="rope"></div>
                            <div className="rope"></div>
                        </div>
                        <div className="ring-posts">
                            <div className="post left"></div>
                            <div className="post right"></div>
                        </div>
                    </div>

                    <h2 className="select-title">Choose Your Fighter to Start</h2>
                    <div className="character-options" role="list" aria-label="Available fighters">
                        <div 
                            className="character-card jake"
                            onClick={() => handlePlayerSelect(characters[0])}
                            role="listitem"
                            aria-label="Select Jake Paul"
                        >
                            <div className="card-inner">
                                <div className="character-image-container">
                                    <div className="hover-glow blue"></div>
                                    <img 
                                        src="/images/jake.png" 
                                        alt="Jake Paul" 
                                        className="character-preview"
                                    />
                                </div>
                                <div className="character-info">
                                    <h4>Jake Paul</h4>
                                    <div className="stats">
                                        <div className="stat">Speed: ★★★★☆</div>
                                        <div className="stat">Power: ★★★☆☆</div>
                                    </div>
                                </div>
                                <div className="select-prompt">Click to Select</div>
                            </div>
                        </div>

                        <div className="vs-container" aria-hidden="true">
                            <div className="vs-circle">
                                <span className="vs-text">VS</span>
                            </div>
                        </div>

                        <div 
                            className="character-card tyson"
                            onClick={() => handlePlayerSelect(characters[1])}
                            role="listitem"
                            aria-label="Select Mike Tyson"
                        >
                            <div className="card-inner">
                                <div className="character-image-container">
                                    <div className="hover-glow red"></div>
                                    <img 
                                        src="/images/tyson.png" 
                                        alt="Mike Tyson" 
                                        className="character-preview"
                                    />
                                </div>
                                <div className="character-info">
                                    <h4>Mike Tyson</h4>
                                    <div className="stats">
                                        <div className="stat">Speed: ★★★☆☆</div>
                                        <div className="stat">Power: ★★★★★</div>
                                    </div>
                                </div>
                                <div className="select-prompt">Click to Select</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            
            {currentPhase === GamePhase.FIGHT && (
                <div className="game-area">
                    <h2 className="visually-hidden">Boxing Match Arena</h2>
                    <canvas
                        ref={canvasRef}
                        width={800}
                        height={600}
                        onClick={jump}
                        aria-label="Boxing game arena"
                        role="application"
                    />
                    {slidingMessageRef.current.active && (
                        <div className="sliding-message-container active">
                            <div className="sliding-message">
                                {slidingMessageRef.current.text}
                            </div>
                        </div>
                    )}
                    {gameOver && (
                        <Modal 
                            title="Game Over!"
                            actionText="Try Again"
                            onAction={startGame}
                        >
                            <div className="score-display">
                                <p className="final-score">Score: {score}</p>
                            </div>
                        </Modal>
                    )}
                </div>
            )}
        </div>
    );
};

export default FightGame; 