import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { analytics } from './firebase/config';
import { logEvent } from "firebase/analytics";
import { Metric } from 'web-vitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Modified to use Firebase Analytics
reportWebVitals((metric: Metric) => {
  logEvent(analytics, 'web_vitals', {
    name: metric.name,
    value: Math.round(metric.value),
    id: metric.id,
  });
});
