import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyBqQX7qCaxXvDpDeaIj7viT_Urp3tkpUN8",
//   authDomain: "miketysonjakepaulfight.firebaseapp.com",
//   projectId: "miketysonjakepaulfight",
//   storageBucket: "miketysonjakepaulfight.firebasestorage.app",
//   messagingSenderId: "736640796428",
//   appId: "1:736640796428:web:50b9bb4477a50845cc6b29",
//   measurementId: "G-NPSPS52DBK"
// };


const firebaseConfig = {
  apiKey: "AIzaSyBuFRKkPpw6HNOtEBqYAvAdKuMpkT79uSQ",
  authDomain: "jakepaulmiketysonfight.firebaseapp.com",
  projectId: "jakepaulmiketysonfight",
  storageBucket: "jakepaulmiketysonfight.firebasestorage.app",
  messagingSenderId: "642746477067",
  appId: "1:642746477067:web:ff98497184dc2e4701350e",
  measurementId: "G-0E4CBPH3MZ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app); 